import { Divider, Stack, Typography } from "@mui/material";
import { Trans, useTranslation } from "react-i18next";
import theme, { shadows } from "../../../../../theme";
import { convertPxToRem } from "../../../../../utils";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import {
  trackEvent,
  trackUserInteraction,
} from "../../../../../services/analytics-adapter";
import HealthScoreIndicatorGauge from "@/features/MyHealth/components/HealthScoreIndicatorGauge";
import IconLoader from "@/features/Common/components/IconLoader";
import LoadingSpinner from "@/features/Common/components/LoadingSpinner";

interface HealthProfileTileProps {
  lastUpdatedDate: string | undefined;
  healthScore: number;
  isLoading: boolean;
  isError: boolean;
  fullWidth?: boolean;
}

export default function HealthProfileTile({
  lastUpdatedDate,
  healthScore,
  isLoading,
  isError,
  fullWidth = false,
}: HealthProfileTileProps) {
  const { t } = useTranslation();
  const [hasHealthScore, setHasHealthScore] = useState(false);
  const navigate = useNavigate();

  const loadedSuccessfully = !isLoading && !isError;

  function onHealthProfileTileClick() {
    trackEvent({
      event: "action.userNav",
      "userNav.destination": "myHealth",
      "userNav.via": "homeScreenTile",
    });
    trackUserInteraction({
      linkText: "Home screen tile | My health",
      linkIntent: "navigational",
      linkScope: "button",
    });
    navigate("/home/my-health/health-profile");
  }

  useEffect(() => {
    if (loadedSuccessfully) {
      if (healthScore === 0 || healthScore === 1) {
        setHasHealthScore(false);
      } else {
        setHasHealthScore(true);
      }
    }
  }, [healthScore]);

  return (
    <>
      <Stack
        onClick={onHealthProfileTileClick}
        component="button"
        aria-label={t("HealthProfileTile.completed.title")}
        borderRadius={2.5}
        p={2}
        spacing={1}
        justifyContent="space-between"
        minHeight={fullWidth ? convertPxToRem(0) : convertPxToRem(195)}
        sx={{
          background: theme.palette.background.paper,
          boxShadow: shadows.light,
          cursor: "pointer",
          border: "none",
        }}
      >
        {isLoading && (
          <Stack height="100%" justifyContent="center" alignSelf="center">
            <LoadingSpinner />
          </Stack>
        )}

        {!isLoading && isError && (
          <Typography alignSelf="center">
            {t("common.somethingWentWrong")}
          </Typography>
        )}

        {loadedSuccessfully && hasHealthScore && (
          <>
            <Stack
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              textAlign="left"
              alignContent="space-between"
              width="100%"
            >
              <Typography variant="body2" fontWeight="bold">
                {t("HealthProfileTile.completed.title")}
              </Typography>
              <IconLoader icon="ChevronNextIcon" color="primary" />
            </Stack>

            <Stack
              width="100%"
              direction={fullWidth ? "row" : "column"}
              justifyContent="space-between"
            >
              <Stack alignSelf="center" pb={1}>
                <HealthScoreIndicatorGauge healthScore={healthScore} />
              </Stack>

              {lastUpdatedDate && (
                <>
                  <Divider
                    orientation={fullWidth ? "vertical" : "horizontal"}
                    flexItem
                  />
                  <Stack alignSelf="center">
                    <Typography
                      variant="body2"
                      sx={{ fontSize: convertPxToRem(11) }}
                    >
                      <Trans
                        i18nKey="HealthProfileTile.lastUpdated"
                        values={{
                          date: lastUpdatedDate?.split("T")[0],
                        }}
                      ></Trans>
                    </Typography>
                  </Stack>
                </>
              )}
            </Stack>
          </>
        )}

        {loadedSuccessfully && !hasHealthScore && (
          <>
            <Stack
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              textAlign="left"
              alignContent="space-between"
              width="100%"
            >
              <Typography variant="body2" fontWeight="bold">
                {t("HealthProfileTile.uncompleted.title")}
              </Typography>
              <IconLoader icon="ChevronNextIcon" color="primary" />
            </Stack>
            <Stack alignSelf="center">
              <IconLoader
                icon="UnuFaceWithMagnifyingGlassIcon"
                sx={{
                  fontSize: convertPxToRem(70),
                }}
              />
            </Stack>
            <Stack alignSelf="center">
              <Typography
                variant="body2"
                color="neutral.light"
                sx={{ fontSize: convertPxToRem(11) }}
              >
                {t("HealthProfileTile.uncompleted.subtitle")}
              </Typography>
            </Stack>
          </>
        )}
      </Stack>
    </>
  );
}
