import {
  getMembershipConfig,
  MemberProfile,
} from "@/services/core-api-adapter";
import {
  FeatureToggleIdentifier,
  isFeatureEnabled,
} from "@/services/feature-toggle-adapter";
import theme, {
  defaultFullscreenPageStyling,
  panelStyle,
  shadows,
} from "@/theme";
import { convertPxToRem } from "@/utils";
import { Box, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { HealthProfileTile } from "../../DashboardTiles";
import LifestyleAndAdviceSection from "../../LifestyleAndAdviceSection";
import MessagesIconCTA from "../../MessagesIconCTA";
import PayGMedicalAssistanceCTA from "../../PayGMedicalAssistanceCTA";
import ProfileIconCTA from "../../ProfileIconCTA";
import { trackEvent, trackUserInteraction } from "@/services/analytics-adapter";
import ButtonWithAnalytics from "@/features/Common/components/ButtonWithAnalytics";
import IconLoader from "@/features/Common/components/IconLoader";

interface PayGMemberDashboardProps {
  memberProfile: MemberProfile | null;
  isMemberProfileLoading: boolean;
  memberProfileFetchError: boolean;
}

export default function PayGMemberDashboardV2({
  memberProfile,
  isMemberProfileLoading,
  memberProfileFetchError,
}: PayGMemberDashboardProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [isIntercareSupported, setIsIntercareSupported] = useState<
    boolean | null
  >(null);

  const isPathologyEnabled = isFeatureEnabled(
    FeatureToggleIdentifier.ENABLE_PATHOLOGY_PANELS
  );

  const serviceOfferingOptions = [
    {
      name: t("PayGMemberDashboard.doctorAndNurseConsult.panel.title"),
      description: t(
        "PayGMemberDashboard.doctorAndNurseConsult.panel.subtitle"
      ),
      route: "/home/get-medical-assistance/intercare",
      leftButton: (
        <Typography fontWeight={600} color="info">
          {t("PayGMemberDashboard.serviceOffering.panel.price", {
            priceFormatted: "R179",
          })}
        </Typography>
      ),
      rightButton: (
        <Typography fontWeight={600} color="primary">
          {t(
            "PayGMemberDashboard.doctorAndNurseConsult.panel.rightButton.title"
          )}
        </Typography>
      ),
    },
    ...(isPathologyEnabled
      ? [
          {
            name: t("PayGMemberDashboard.bloodTests.panel.title"),
            description: t("PayGMemberDashboard.bloodTests.panel.subtitle"),
            route: "/home/get-medical-assistance/pathology",
            leftButton: (
              <Typography fontWeight={600} color="info">
                {t("PayGMemberDashboard.serviceOffering.panel.price", {
                  priceFormatted: "R184",
                })}
              </Typography>
            ),
            rightButton: (
              <Typography fontWeight={600} color="primary">
                {t("PayGMemberDashboard.bloodTests.panel.rightButton.title")}
              </Typography>
            ),
          },
        ]
      : []),
    {
      name: t("PayGMemberDashboard.healthCheck.panel.title"),
      description: t("PayGMemberDashboard.healthCheck.panel.subtitle"),
      route: "/binah-scan?skipScanPrompt=true",
      leftButton: (
        <Typography fontWeight={600} color="info">
          {t("common.free")}
        </Typography>
      ),
      rightButton: (
        <Typography fontWeight={600} color="primary">
          {t("PayGMemberDashboard.healthCheck.panel.rightButton.title")}
        </Typography>
      ),
    },
  ];

  function onBellIconButtonClick() {
    navigate("/home/messages");
  }

  function onProfileIconCTAClick() {
    trackEvent({
      event: "action.myProfileCTAClicked",
      source: "home screen",
    });

    trackUserInteraction({
      linkText: "Home screen | My profile",
      linkIntent: "navigational",
      linkScope: "button",
    });

    navigate("/home/my-profile");
  }

  function onPanelClick(route: string) {
    navigate(route);
  }

  useEffect(() => {
    if (isMemberProfileLoading === false) {
      const membershipConfig = getMembershipConfig(
        memberProfile?.membership?.productCode
      );

      setIsIntercareSupported(
        (isFeatureEnabled(
          FeatureToggleIdentifier.ENABLE_INTERCARE_MEDICAL_ASSISTANCE
        ) &&
          membershipConfig?.isIntercareSupported) ||
          false
      );
    }
  }, [memberProfile, isMemberProfileLoading]);

  return (
    <Stack spacing={2}>
      <Box
        p={2}
        gap={2}
        sx={{
          borderRadius: `0 0 ${convertPxToRem(15)}  ${convertPxToRem(15)}`,
          background: `linear-gradient(to bottom, ${theme.palette.primary.contrastText}, ${theme.palette.accent2.light}, ${theme.palette.accent2.light})`,
          width: "100%",
          boxShadow: shadows.light,
        }}
      >
        <Stack direction="row" width="100%" justifyContent="end">
          <Stack display="flex" alignItems="end" position="relative">
            <MessagesIconCTA onClick={onBellIconButtonClick} />
          </Stack>
          {isFeatureEnabled(FeatureToggleIdentifier.ENABLE_SENS_UPDATES) && (
            <Stack display="flex" alignItems="end" position="relative">
              <ProfileIconCTA onClick={onProfileIconCTAClick} />
            </Stack>
          )}
        </Stack>
        <Typography
          variant="body1"
          sx={{
            width: "100%",
            fontSize: convertPxToRem(20),
          }}
        >
          {memberProfile && memberProfile?.firstName?.length > 0
            ? t("Home.title.withName", {
                name: memberProfile.firstName,
              })
            : t("Home.title.withoutName")}
        </Typography>
        <Typography
          variant="h1"
          sx={{
            width: "100%",
            fontSize: convertPxToRem(24),
          }}
        >
          {t("Home.helpSubtitle")}
        </Typography>

        {isIntercareSupported === true && (
          <Stack mt={1} sx={{ zIndex: 2 }}>
            <PayGMedicalAssistanceCTA
              memberProfile={memberProfile}
              isLoading={isMemberProfileLoading}
              onlyDisplayWhenActiveSession={true}
            />
          </Stack>
        )}
      </Box>

      <Stack sx={{ zIndex: 2 }} spacing={2} p={2}>
        <Stack spacing={2}>
          {serviceOfferingOptions.map((item) => (
            <Stack
              key={item.name}
              sx={{
                ...panelStyle,
                cursor: "pointer",
                border: "none",
              }}
              component="button"
              aria-label={item.name}
              spacing={1}
              onClick={() => onPanelClick(item.route)}
            >
              <Typography textAlign="left" variant="h4" fontWeight={600}>
                {item.name}
              </Typography>
              <Typography
                textAlign="left"
                variant="body2"
                color="neutral.light"
              >
                {item.description}
              </Typography>
              <Stack
                flexDirection="row"
                justifyContent="space-between"
                width="100%"
              >
                <ButtonWithAnalytics size="small">
                  {item.leftButton}
                </ButtonWithAnalytics>
                <ButtonWithAnalytics
                  variant="text"
                  endIcon={<IconLoader icon="ChevronNextIcon" />}
                >
                  {item.rightButton}
                </ButtonWithAnalytics>
              </Stack>
            </Stack>
          ))}
        </Stack>
        <Stack spacing={2}>
          <Stack
            justifyContent="space-between"
            flexDirection="row"
            alignItems="center"
          >
            <Typography component="h2" fontWeight={600}>
              {t("common.healthInfo")}
            </Typography>
            <Box
              sx={{
                p: 1,
                background: theme.palette.accent1.main,
                borderRadius: 2,
              }}
            >
              <Typography
                variant="body2"
                fontWeight={600}
                color="accent1.contrastText"
              >
                {t("common.freeInfo")}
              </Typography>
            </Box>
          </Stack>

          <HealthProfileTile
            lastUpdatedDate={memberProfile?.healthProfile?.lastUpdatedAt}
            healthScore={
              Number(memberProfile?.healthProfile?.healthScoreValue) || 0
            }
            isLoading={isMemberProfileLoading}
            isError={memberProfileFetchError}
            fullWidth
          />

          {isFeatureEnabled(FeatureToggleIdentifier.ENABLE_CHAT_BOT) && (
            <LifestyleAndAdviceSection
              displayTitle={false}
              displayTag={false}
            />
          )}
        </Stack>
      </Stack>
    </Stack>
  );
}
