import { MemberProfile } from "@/services/core-api-adapter";
import {
  FeatureToggleIdentifier,
  isFeatureEnabled,
} from "@/services/feature-toggle-adapter";
import PayGMemberDashboardV2 from "./v2";
import PayGMemberDashboardV1 from "./v1";

interface PayGMemberDashboardProps {
  memberProfile: MemberProfile | null;
  isMemberProfileLoading: boolean;
  memberProfileFetchError: boolean;
}

export default function PayGMemberDashboard({
  memberProfile,
  isMemberProfileLoading,
  memberProfileFetchError,
}: PayGMemberDashboardProps) {
  if (
    isFeatureEnabled(FeatureToggleIdentifier.ENABLE_IMPROVED_SERVICE_OFFERING)
  ) {
    return (
      <PayGMemberDashboardV2
        memberProfile={memberProfile}
        isMemberProfileLoading={isMemberProfileLoading}
        memberProfileFetchError={memberProfileFetchError}
      />
    );
  }

  return (
    <PayGMemberDashboardV1
      memberProfile={memberProfile}
      isMemberProfileLoading={isMemberProfileLoading}
      memberProfileFetchError={memberProfileFetchError}
    />
  );
}
