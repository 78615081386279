import MembershipDashboardPanel from "@/features/Dashboard/components/MembershipDashboardPanel";
import useGetDirectToDoctorPrimarySymptoms from "@/features/Common/hooks/useGetDirectToDoctorPrimarySymptoms";
import useGetMemberships from "@/features/Common/hooks/useGetMemberships";
import {
  getMembershipConfig,
  MemberProfile,
} from "@/services/core-api-adapter";
import {
  FeatureToggleIdentifier,
  isFeatureEnabled,
} from "@/services/feature-toggle-adapter";
import theme, { defaultFullscreenPageStyling, shadows } from "@/theme";
import { convertPxToRem } from "@/utils";
import { Box, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import {
  BenefitsTile,
  HealthCheckTile,
  HealthProfileTile,
  MyPeopleTile,
} from "../../DashboardTiles";
import LifestyleAndAdviceSection from "../../LifestyleAndAdviceSection";
import MessagesIconCTA from "../../MessagesIconCTA";
import PayGMedicalAssistanceCTA from "../../PayGMedicalAssistanceCTA";
import DirectToDoctorSlider from "../../DirectToDoctorSlider";
import PathologyPanelSlider from "../../PathologyPanelSlider";
import { useGlobalStore } from "@/store";
import ProfileIconCTA from "../../ProfileIconCTA";
import { trackEvent, trackUserInteraction } from "@/services/analytics-adapter";

interface PayGMemberDashboardProps {
  memberProfile: MemberProfile | null;
  isMemberProfileLoading: boolean;
  memberProfileFetchError: boolean;
}

export default function PayGMemberDashboardV1({
  memberProfile,
  isMemberProfileLoading,
  memberProfileFetchError,
}: PayGMemberDashboardProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { state } = useGlobalStore();
  const [isIntercareSupported, setIsIntercareSupported] = useState<
    boolean | null
  >(null);

  const isPathologyEnabled = isFeatureEnabled(
    FeatureToggleIdentifier.ENABLE_PATHOLOGY_PANELS
  );

  const isSensUpdatesEnabled = isFeatureEnabled(
    FeatureToggleIdentifier.ENABLE_SENS_UPDATES
  );

  const isRetailMember = state.currentUser.isRetailMember;

  const {
    firstMembership,
    memberships,
    membershipsFetchError,
    isMembershipsLoading,
  } = useGetMemberships();

  function onBellIconButtonClick() {
    navigate("/home/messages");
  }

  const {
    primarySymptoms,
    primarySymptomsFetchError,
    isPrimarySymptomsLoading,
  } = useGetDirectToDoctorPrimarySymptoms();

  function onProfileIconCTAClick() {
    trackEvent({
      event: "action.myProfileCTAClicked",
      source: "home screen",
    });

    trackUserInteraction({
      linkText: "Home screen | My profile",
      linkIntent: "navigational",
      linkScope: "button",
    });

    navigate("/home/my-profile");
  }

  useEffect(() => {
    if (isMemberProfileLoading === false) {
      const membershipConfig = getMembershipConfig(
        memberProfile?.membership?.productCode
      );

      setIsIntercareSupported(
        (isFeatureEnabled(
          FeatureToggleIdentifier.ENABLE_INTERCARE_MEDICAL_ASSISTANCE
        ) &&
          membershipConfig?.isIntercareSupported) ||
          false
      );
    }
  }, [memberProfile, isMemberProfileLoading]);

  return (
    <Stack sx={{ ...defaultFullscreenPageStyling }}>
      <Box
        sx={{
          borderRadius: `0 0 ${convertPxToRem(15)}  ${convertPxToRem(15)}`,
          background: `linear-gradient(to top, ${theme.palette.primary.contrastText}, ${theme.palette.accent2.light}, ${theme.palette.accent2.light})`,
          position: "absolute",
          height: convertPxToRem(350),
          width: "100%",
          left: 0,
          top: 0,
          zIndex: 1,
          boxShadow: shadows.light,
        }}
      />

      <Stack sx={{ zIndex: 2 }}>
        <Stack direction="row" spacing={1} width="100%" justifyContent="end">
          <Stack display="flex" alignItems="end" position="relative">
            <MessagesIconCTA onClick={onBellIconButtonClick} />
          </Stack>
          {isFeatureEnabled(FeatureToggleIdentifier.ENABLE_SENS_UPDATES) && (
            <Stack display="flex" alignItems="end" position="relative">
              <ProfileIconCTA onClick={onProfileIconCTAClick} />
            </Stack>
          )}
        </Stack>
        <Stack spacing={1}>
          <Typography
            variant="body1"
            sx={{
              width: "100%",
              fontSize: convertPxToRem(20),
            }}
          >
            {memberProfile && memberProfile?.firstName?.length > 0
              ? t("Home.title.withName", {
                  name: memberProfile.firstName,
                })
              : t("Home.title.withoutName")}
          </Typography>
          <Typography
            variant="h1"
            sx={{
              width: "100%",
              fontSize: convertPxToRem(24),
            }}
          >
            {t("Home.helpSubtitle")}
          </Typography>

          <Stack sx={{ zIndex: 2 }}>
            {isIntercareSupported === true && (
              <PayGMedicalAssistanceCTA
                memberProfile={memberProfile}
                isLoading={isMemberProfileLoading}
              />
            )}
          </Stack>
        </Stack>

        {!isPrimarySymptomsLoading &&
          !primarySymptomsFetchError &&
          primarySymptoms?.length > 0 && (
            <>
              <Stack
                spacing={2}
                mt={2}
                sx={{
                  width: "106%",
                  [theme.breakpoints.down("md")]: {
                    width: "100vw",
                  },
                  position: "relative",
                  left: `${convertPxToRem(-16)}`,
                }}
              >
                <Stack
                  flexDirection="row"
                  justifyContent="space-between"
                  sx={{
                    padding: `0 ${convertPxToRem(16)}`,
                  }}
                >
                  <Stack
                    width="100%"
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Typography fontWeight="bold" component="h2">
                      {t("DirectToDoctor.primarySymptoms.homescreen.title")}
                    </Typography>
                  </Stack>
                </Stack>
                <DirectToDoctorSlider
                  maxItems={5}
                  displayViewAllButton={true}
                  primarySymptoms={primarySymptoms}
                />
              </Stack>
            </>
          )}

        {isPathologyEnabled && (
          <>
            <Stack
              mt={2}
              spacing={2}
              sx={{
                width: "106%",
                [theme.breakpoints.down("md")]: {
                  width: "100vw",
                },
                position: "relative",
                left: `${convertPxToRem(-16)}`,
              }}
            >
              <Stack
                flexDirection="row"
                justifyContent="space-between"
                sx={{
                  padding: `0 ${convertPxToRem(16)}`,
                }}
              >
                <Stack
                  width="100%"
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Typography fontWeight="bold" component="h2">
                    {t("PathologyPanelsSlider.title")}
                  </Typography>
                </Stack>
              </Stack>
              <PathologyPanelSlider maxItems={5} displayViewAllButton={true} />
            </Stack>
          </>
        )}

        <Stack spacing={2}>
          <Typography component="h2" fontWeight={600}>
            {t("common.myProfile")}
          </Typography>

          <Stack
            direction="row"
            sx={{
              display: "grid",
              gridTemplateColumns: "repeat(2, 1fr)",
            }}
            gap={2}
          >
            <HealthCheckTile
              memberProfile={memberProfile}
              isLoading={isMemberProfileLoading}
              shouldShowServiceUsage={true}
            />
            <HealthProfileTile
              lastUpdatedDate={memberProfile?.healthProfile?.lastUpdatedAt}
              healthScore={
                Number(memberProfile?.healthProfile?.healthScoreValue) || 0
              }
              isLoading={isMemberProfileLoading}
              isError={memberProfileFetchError}
            />
            {!isSensUpdatesEnabled || !isRetailMember ? (
              <>
                <BenefitsTile
                  benefitCount={memberProfile?.benefitCount}
                  isLoading={isMemberProfileLoading}
                  isError={memberProfileFetchError}
                />
                <MyPeopleTile
                  membershipId={memberProfile?.membership.id}
                  dependentsCount={memberProfile?.dependentCount}
                  isLoading={isMemberProfileLoading}
                  isError={memberProfileFetchError}
                />
              </>
            ) : null}
          </Stack>
          {isFeatureEnabled(FeatureToggleIdentifier.ENABLE_CHAT_BOT) && (
            <LifestyleAndAdviceSection />
          )}
        </Stack>
      </Stack>
      {!isSensUpdatesEnabled || !isRetailMember ? (
        <Stack mt={4} spacing={5}>
          <MembershipDashboardPanel
            memberProfile={memberProfile}
            membershipData={{
              firstMembership,
              memberships,
              membershipsFetchError,
              isMembershipsLoading,
            }}
          />
        </Stack>
      ) : null}
    </Stack>
  );
}
